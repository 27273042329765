import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import HomeView from "@/views/form";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: (to, from, next) => {
      store
        .dispatch("Verification", to)
        .then(() => {
          next();
        })
        .catch(() => {
          next("/error");
        });
    },
  },
  {
    path: "/secrectPage",
    name: "SecrectPage",
    component: () =>
      import(/* webpackChunkName: "form" */ "@/views/form/SecrectPage.vue"),
  },
  {
    path: "/success",
    name: "Success",
    component: () =>
      import(/* webpackChunkName: "success" */ "@/views/success"),
  },
  {
    path: "/error",
    name: "Error",
    component: () => import(/* webpackChunkName: "error" */ "@/views/error"),
  },
  {
    path: "/signature",
    name: "Signature",
    component: () =>
      import(/* webpackChunkName: "signature" */ "@/views/signature"),
  },
  {
    path: "/signSuccess",
    name: "SignSuccess",
    component: () =>
      import(
        /* webpackChunkName: "signature" */ "@/views/signature/success.vue"
      ),
  },
  {
    path: "/eventApply",
    name: "eventApply",
    component: () =>
      import(
        /* webpackChunkName: "signature" */ "@/views/apply/eventApply.vue"
      ),
  },
  {
    path: "/applySuccess",
    name: "applySuccess",
    component: () =>
      import(
        /* webpackChunkName: "signature" */ "@/views/apply/applySuccess.vue"
      ),
  },
  {
    path: "/billPay",
    name: "billPay",
    component: () =>
      import(/* webpackChunkName: "BillPay" */ "@/views/bill/BillPay.vue"),
  },
  {
    path: "/studyCamp",
    name: "studyCamp",
    component: () =>
      import(/* webpackChunkName: "StudyCamp" */ "@/views/studyCamp/index.vue"),
  },
  {
    path: "/studyCamp/intro",
    name: "studyCampIntro",
    component: () =>
      import(/* webpackChunkName: "StudyCamp" */ "@/views/studyCamp/intro.vue"),
  },
  {
    path: "/studyCamp/signUp",
    name: "studyCampSignUp",
    component: () =>
      import(
        /* webpackChunkName: "StudyCamp" */ "@/views/studyCamp/signUp.vue"
      ),
  },
  {
    path: "/studyCamp/pay",
    name: "studyCampPay",
    component: () =>
      import(/* webpackChunkName: "StudyCamp" */ "@/views/studyCamp/pay.vue"),
  },
  {
    path: "/studyCamp/attendance",
    name: "studyCampAttendance",
    component: () =>
      import(
        /* webpackChunkName: "StudyCamp" */ "@/views/studyCamp/attendance.vue"
      ),
  },
  {
    path: "/studyCamp/attendanceSuccess",
    name: "studyCampAttendanceSuccess",
    component: () =>
      import(
        /* webpackChunkName: "StudyCamp" */ "@/views/studyCamp/attendanceSuccess.vue"
      ),
  },
  {
    path: "/trainCamp/signUp",
    name: "TrainCampSignUp",
    component: () =>
      import(
        /* webpackChunkName: "TrainCamp" */ "@/views/trainCamp/signUp.vue"
      ),
  },
  {
    path: "/trainCamp/result",
    name: "TrainCampSignUpResult",
    component: () =>
      import(
        /* webpackChunkName: "TrainCamp" */ "@/views/trainCamp/result.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
